import React from 'react';

const Footer = () => {
    return (
        <footer>
          <p>Copyright &copy; 2020 Andykey</p>
        </footer>
    );
};

export default Footer;