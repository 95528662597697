// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCpYRvUylEpllK_okfsvUFEX0nxtH89vvY",
  authDomain: "ecommerce-demo-84728.firebaseapp.com",
  databaseURL: "https://ecommerce-demo-84728.firebaseio.com",
  projectId: "ecommerce-demo-84728",
  storageBucket: "ecommerce-demo-84728.appspot.com",
  messagingSenderId: "1041531026510",
  appId: "1:1041531026510:web:3d123b2d724023705a971f",
  measurementId: "G-9TX59XG0W0"
};